import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FarmerService {

  public baseUrl = environment.apiBaseUrl + '/farmer';
  constructor(private http: HttpClient) { }

   getAllFarmer = () => {
    return this.http.get(`${this.baseUrl}/getAll`);
  }

  getActiveFarmer = () => {
    return this.http.get(`${this.baseUrl}/active`);
  }

  saveFarmer = (data) => {
    return this.http.post(`${this.baseUrl}/save`,data);
  }

  updateFarmer = (data) => {
    return this.http.patch(`${this.baseUrl}/update`,data);
  }

  deleteFarmer = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.delete(`${this.baseUrl}/delete`, { params });
  }

  updateFarmerStatus = (data) => {
    return this.http.put(`${this.baseUrl}/status`,data);
  }

  getFarmerDetails = (data) => {
    return this.http.put(`${this.baseUrl}/byId`,data);
  }
}
