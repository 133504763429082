import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryPersonService {

  public baseUrl = environment.apiBaseUrl + '/deliveryPerson';
  constructor(private http: HttpClient) { }

   getAllDeliveryPerson = (data) => {
    return this.http.post(`${this.baseUrl}/getAll`, data);
  }

  getActiveDeliveryPerson = () => {
    return this.http.get(`${this.baseUrl}/active`);
  }

  saveDeliveryPerson = (data) => {
    return this.http.post(`${this.baseUrl}/save`,data);
  }

  updateDeliveryPerson = (data) => {
    return this.http.patch(`${this.baseUrl}/update`,data);
  }

  deleteDeliveryPerson = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.delete(`${this.baseUrl}/delete`, { params });
  }

  updateDeliveryPersonStatus = (data) => {
    return this.http.put(`${this.baseUrl}/status`,data);
  }

  getDeliveryPersonbyId = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.get(`${this.baseUrl}/getDeliveryPersonbyId`, { params });
  }
  getDeliveryPersonByFarmCircleId(id){
    var params = new HttpParams().set('id', id);
    return this.http.get(`${this.baseUrl}/getDeliveryPersonByFarmCircleId`, { params });
  }
  saveDeliveryDetails = (data) => {
    return this.http.post(`${this.baseUrl}/saveDeliveryDetails`,data);
  }
  getDeliveryDetailsByOrderId(id) {
     var params = new HttpParams().set('id', id);
    return this.http.get(`${this.baseUrl}/orderId`, { params });
  }

  getDeliveryPersonDetails = (data) => {
    return this.http.put(`${this.baseUrl}/byId`,data);
  }

}