import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {


  public baseUrl = environment.apiBaseUrl + '/purchase';
  constructor(private http: HttpClient) { }

   getAllPurchases = () => {
    return this.http.get(`${this.baseUrl}/getAll`);
  }

  getActivePurchase = (data) => {
    return this.http.post(`${this.baseUrl}/active`,data);
  }

  savePurchase = (data) => {
    return this.http.post(`${this.baseUrl}/save`,data);
  }

  updatePurchase = (data) => {
    return this.http.patch(`${this.baseUrl}/update`,data);
  }

  deletePurchase = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.delete(`${this.baseUrl}/delete`, { params });
  }

  updatePurchaseStatus = (data) => {
    return this.http.put(`${this.baseUrl}/status`,data);
  }

  deletePurchaseItem = (data) => {
    return this.http.patch(`${this.baseUrl}/deleteItem`,data);
  }

  saveTempRequirement = (data) => {
    return this.http.post(`${this.baseUrl}/saveTempReq`,data);
  }

  getTempRequirement= () => {
    return this.http.get(`${this.baseUrl}/getTemp`);
  }
}
