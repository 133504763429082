import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BinService {

  public baseUrl = environment.apiBaseUrl + '/bin';
  constructor(private http: HttpClient) { }

   getAllBin = (data) => {
    return this.http.post(`${this.baseUrl}/getAll`,data);
  }

  getActiveBin = (data) => {
    return this.http.post(`${this.baseUrl}/active`,data);
  }

  saveBin = (data) => {
    return this.http.post(`${this.baseUrl}/save`,data);
  }

  updateBin = (data) => {
    return this.http.patch(`${this.baseUrl}/update`,data);
  }

  deleteBin = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.delete(`${this.baseUrl}/delete`, { params });
  }

  updateBinStatus = (data) => {
    return this.http.put(`${this.baseUrl}/status`,data);
  }
}
